@import './mixins';

$colors: (
  primary: #345e3b,
  secondary: #e7b727,
  warning: #cf2136,
  text: #0e1a11,
  link: #345e3b,
);


:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
  --font-family: "WorkSans", sans-serif;
  --body-background-color: var(--text-tint-97);
  --bold-font-weight: 800;
  --inner-content-width: 1000px;
  --max-content-width: 1500px;
  --content-margin: 20px;
}

@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "WorkSans";
  font-weight: 800;
  src: url("/assets/fonts/work-sans/WorkSans-Bold.ttf");
}
@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans/WorkSans-BoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "WorkSans";
  src: url("/assets/fonts/work-sans/WorkSans-Italic.ttf");
  font-style: italic;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: 16px;
}

a {
  color: var(--primary-color);
}

sm-table {
  tfoot {
    td {
      background-color: var(--text-tint-87);
      font-weight: var(--bold-font-weight);
      padding: 9px 15px;

      @media print {
        padding: 0;
      }

      &:first-child {
        border-radius: 0 0 0 7px;
      }
      &:last-child {
        border-radius: 0 0 7px 0;
      }
    }
  }
}


button.stroked {
  &.stroked.size-md,
  &.size-md {
    border-width: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

sm-loading {
  fill: var(--primary-color);
}
/*
  --primary-color: #345e3b;
  --secondary-color: #e7b727;
  --warning-color: #cf2136;
  --text-color: #101E13;
  --link-color: #345e3b;
  --primary-tint-11: #4a7051;
  --primary-tint-25: #67866c;
  --primary-tint-45: #8fa693;
  --primary-tint-66: #bac8bc;
  --primary-tint-79: #d4ddd6;
  --primary-tint-87: #e5eae6;
  --primary-tint-92: #eff2ef;
  --primary-tint-95: #f5f7f5;
  --primary-tint-97: #f9faf9;
  --secondary-tint-11: #eabf3f;
  --secondary-tint-25: #edc95d;
  --secondary-tint-45: #f2d788;
  --secondary-tint-66: #f7e7b6;
  --secondary-tint-79: #faf0d2;
  --secondary-tint-87: #fcf6e3;
  --secondary-tint-92: #fdf9ee;
  --secondary-tint-95: #fefbf4;
  --secondary-tint-97: #fefdf9;
  --warning-tint-11: #d4394c;
  --warning-tint-25: #db5968;
  --warning-tint-45: #e58590;
  --warning-tint-66: #efb4bb;
  --warning-tint-79: #f5d0d5;
  --warning-tint-87: #f9e2e5;
  --warning-tint-92: #fbedef;
  --warning-tint-95: #fdf4f5;
  --warning-tint-97: #fef8f9;
  --text-tint-11: #2a372d;
  --text-tint-25: #4c564e;
  --text-tint-45: #7c837d;
  --text-tint-66: #aeb3af;
  --text-tint-79: #cdd0cd;
  --text-tint-87: #e0e2e0;
  --text-tint-92: #ecedec;
  --text-tint-95: #f3f4f3;
  --text-tint-97: #f8f8f8;
  --link-tint-11: #4a7051;
  --link-tint-25: #67866c;
  --link-tint-45: #8fa693;
  --link-tint-66: #bac8bc;
  --link-tint-79: #d4ddd6;
  --link-tint-87: #e5eae6;
  --link-tint-92: #eff2ef;
  --link-tint-95: #f5f7f5;
  --link-tint-97: #f9faf9;
  --primary-shade-21: #294a2f;
  --secondary-shade-21: #b6911f;
  --warning-shade-21: #a41a2b;
  --text-shade-21: #0d180f;
  --link-shade-21: #294a2f;
  */
